import {
  format,
  startOfToday,
  endOfToday,
  startOfMonth,
  endOfMonth,
  isSunday,
  isSaturday,
  nextSunday,
  nextSaturday,
  endOfDay,
  addYears,
  startOfDay,
  formatISO,
} from 'date-fns'

import { DateRangeFilterValues } from 'constants/event-filters'
import { getServerTime } from './time'

const getTimeInApiFormat = (date: Date, iso = false): string => {
  if (!iso) {
    return format(date, 'yyyy-MM-dd HH:mm:ss')
  }

  return formatISO(date).match(/\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g)[0]
}

const getServerTimeInApiFormat = (iso = false): string => getTimeInApiFormat(getServerTime(), iso)

const getServerTimeNoSecondsInApiFormat = (iso = false): string =>
  format(getServerTime(), iso ? `yyyy-MM-dd'T'HH:mm` : 'yyyy-MM-dd HH:mm')

const getEventsDateRangeFilters = (range?: string) => {
  let eventStartDateLocalGte = startOfToday()
  let eventStartDateLocalLte = addYears(startOfToday(), 50)

  const cDate = new Date()
  const convertDate = (date: Date) => getTimeInApiFormat(date, true)

  if (range === DateRangeFilterValues.today) {
    eventStartDateLocalGte = startOfToday()
    eventStartDateLocalLte = endOfToday()
  }

  if (range === DateRangeFilterValues.weekend) {
    if (isSaturday(cDate)) {
      eventStartDateLocalGte = startOfToday()
      eventStartDateLocalLte = endOfDay(nextSunday(cDate))
    }

    if (isSunday(cDate)) {
      eventStartDateLocalGte = startOfToday()
      eventStartDateLocalLte = endOfToday()
    }

    eventStartDateLocalGte = startOfDay(nextSaturday(cDate))
    eventStartDateLocalLte = endOfDay(nextSunday(cDate))
  }

  if (range === DateRangeFilterValues.month) {
    eventStartDateLocalGte = startOfMonth(cDate)
    eventStartDateLocalLte = endOfMonth(cDate)
  }

  if (range) {
    // TODO: parse custom string
  }

  const res = {
    eventStartDateLocalGte: convertDate(eventStartDateLocalGte),
    eventStartDateLocalLte: convertDate(eventStartDateLocalLte),
  }

  return res
}

export { getEventsDateRangeFilters, getServerTimeInApiFormat, getServerTimeNoSecondsInApiFormat }
