/* eslint-disable no-nested-ternary */
import { QueryHookOptions, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { FC, isValidElement, ReactNode, useMemo } from 'react'

import ItemsList, { ItemsListProps } from 'components/shared/ItemsList'
import { Button, GlowHeading, Grid } from 'components/ui'
import { GetEventsQuery, GetEventsQueryVariables } from 'gql'
import { GET_EVENTS } from 'gql/queries'
import { useDataLayer, useScreenClass } from 'hooks'
import { EVENT, EVENT_VIP } from 'routes/paths'
import { getImageUrl, getSellingFastLabelIndex, getServerTimeInApiFormat, time } from 'utils'

export type Options = {
  returnNullIfNoEvents?: boolean
  lastParentCategories?: string[]
  withVenueImage?: boolean
  ticketsLabel?: string
  packagesLabel?: string
  venueName?: boolean
}

export type EventsProps = {
  queryOptions: QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>
  filterData?: (value: GetEventsQuery['allArtworks']['edges'][0]['node']) => boolean
  itemListProps?: Partial<ItemsListProps>
  options?: Options
  hideTevoLink?: boolean
  title?: ReactNode | string
  hideTitleIfNoData?: boolean
  withSellingFastLabel?: boolean
  widget?: boolean
}

const EventsList: FC<EventsProps> = ({
  itemListProps = {},
  hideTitleIfNoData = false,
  filterData = () => true,
  options = {},
  hideTevoLink,
  queryOptions,
  title,
  withSellingFastLabel,
  widget,
}) => {
  const eventStartDateGte = useMemo(() => getServerTimeInApiFormat(true), [])

  const {
    data: events,
    loading,
    fetchMore,
  } = useQuery<GetEventsQuery, GetEventsQueryVariables>(GET_EVENTS, {
    notifyOnNetworkStatusChange: true,
    ...queryOptions,
    variables: {
      eventStartDateGte,
      orderBy: 'event_start_date_local',
      salesAs: 'buy_it_now_option',
      featuredDateIsNull: false,
      ...queryOptions.variables,
    },
  })

  const { asPath } = useRouter()

  const dl = useDataLayer()

  const onClick =
    asPath.includes('stars') || asPath.includes('tours')
      ? {
          onClick: () => {
            dl.push({ event: 'click_on_event_cell' })
          },
        }
      : {}

  const { isMobile } = useScreenClass()

  const pageInfo = events?.allArtworks.pageInfo

  const onLoadMore = () => {
    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    })
  }

  const items: ItemsListProps['items'] = events?.allArtworks?.edges
    .filter(({ node }) => filterData(node))
    .map(
      (
        {
          node: {
            venue: { name, city, state, venuePhoto },
            eventStartDateLocal,
            tevoId,
            slug: eventSlug,
            externalLink,
            externalVipLink,
            hasPackages,
            title: t,
          },
        },
        index
      ) => ({
        date: time.customDateFormatter(eventStartDateLocal, 'MMM,dd,EEE ').split(','),
        eventSlug,
        sellingFast:
          withSellingFastLabel &&
          index === getSellingFastLabelIndex(eventSlug, events?.allArtworks?.edges.length),
        fields: isMobile
          ? [
              [
                {
                  text: `${time.customDateFormatter(
                    eventStartDateLocal,
                    'haaa  · '
                  )}${city?.trim()}, ${state}`,
                  bold: true,
                  size: 'xl',
                },
                {
                  text:
                    options?.lastParentCategories?.includes('Sports') || !options.venueName
                      ? t
                      : name,
                  gray: true,
                  size: 'sm',
                },
              ],
            ]
          : [
              [
                {
                  text: time.formatDate(eventStartDateLocal, 'dateOnly'),
                  bold: true,
                },
                { text: time.formatDate(eventStartDateLocal, 'dayAndTime'), gray: true },
              ],
              [
                {
                  text: `${city?.trim()}, ${state}`,
                  bold: true,
                },
                {
                  text:
                    options?.lastParentCategories?.includes('Sports') || !options.venueName
                      ? t
                      : name,
                  gray: true,
                },
              ],
            ],
        onlyVip: (!tevoId && hasPackages) || hideTevoLink,
        external: !!externalLink || !!externalVipLink,
        link:
          externalVipLink ||
          (hasPackages && EVENT_VIP(eventSlug)) ||
          externalLink ||
          (!hideTevoLink && EVENT(eventSlug)),
        imageSrc: options.withVenueImage ? getImageUrl(venuePhoto, 200, 200) : null,
        actions: [
          externalLink && (
            <Button
              {...onClick}
              variant="primary"
              href={externalLink}
              key={externalLink}
              bodyClr="white"
              mode="external-link"
              rel="noopener noreferrer"
              target="_blank"
              fontClr="black"
            >
              {options.ticketsLabel || 'Tickets'}
            </Button>
          ),
          !externalLink && tevoId && !hideTevoLink && (
            <Button
              variant="primary"
              bodyClr="white"
              mode="link"
              href={EVENT(eventSlug)}
              key={EVENT(eventSlug)}
              fontClr="black"
              {...onClick}
            >
              Get Tickets
            </Button>
          ),
          externalVipLink && (
            <Button
              variant="primary"
              icon="star-filled"
              mode="external-link"
              {...onClick}
              href={externalVipLink}
              key={externalVipLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {options.packagesLabel || 'VIP'}
            </Button>
          ),
          !externalVipLink && hasPackages && (
            <Button
              variant="primary"
              icon="star-filled"
              {...onClick}
              mode="link"
              href={EVENT_VIP(eventSlug)}
              key={EVENT_VIP(eventSlug)}
            >
              {options.packagesLabel || 'VIP'}
            </Button>
          ),
        ],
      })
    )

  if (!loading && !items?.length && options?.returnNullIfNoEvents) return null

  return (
    <Grid gap={18}>
      {hideTitleIfNoData && !items?.length ? null : isValidElement(title) ? (
        title
      ) : (
        <GlowHeading Tag="h2" glow={false} fill>
          {title as string}
        </GlowHeading>
      )}

      <ItemsList
        showLoadMoreButton={pageInfo?.hasNextPage}
        onLoadMore={onLoadMore}
        iconForMobile="ticket"
        loading={loading}
        items={items}
        {...itemListProps}
        widget={widget}
      />
    </Grid>
  )
}

export default EventsList
