import {
  convertToLocalTime,
  convertToTimeZone as convertToTimeZoneDateFns,
} from 'date-fns-timezone'
import { format as formatDateFns, differenceInSeconds } from 'date-fns'

export const LOCALE = 'en-US'
export const SERVER_TIMEZONE = 'UTC'

export const TBD_LABEL = 'TBD'

export enum DATE_FORMATS {
  long = 'EEE, MMMM do yyyy p',
  short = 'MM/dd/yyyy EEE',
  dateOnly = 'MMM dd yyyy',
  timeOnly = 'p',
  dayOnly = 'EE',
  dayAndTime = `EE · p`,
  dateOnlyV2 = 'MM/dd/yyyy',
  default = 'MMM do yyyy p',
}

type DateInput = Date | string | number

/**
 * Returns current date on the server timezone
 */
export const getServerDate = () =>
  new Date(
    convertToTimeZoneDateFns(new Date(), {
      timeZone: SERVER_TIMEZONE,
    })
  )

/**
 * Fix for Safari browser https://burningthumb.com/safari-javascript-date-format/
 * Accepts date in string format and formats it to the Safari compatible format.
 * Creates new Date if there is Date or number on the input.
 * Use this method to format all dates that came from API.
 */
export const convertDate = (date: DateInput) => {
  if (typeof date === 'string' || date instanceof String) {
    return new Date(date.replace(/-/g, '/').replace(/T/g, ' '))
  }

  return new Date(date)
}

/**
 * Converts date from server timezone to the client.
 */
export const convertServerDateToClient = (date: DateInput) => {
  const compatibleDate = convertDate(date)

  return convertToLocalTime(compatibleDate, {
    timeZone: SERVER_TIMEZONE,
  })
}

/**
 * Converts date from the client timezone to server.
 */
export const convertClientDateToServer = (date: DateInput) => {
  const compatibleDate = convertDate(date)

  return new Date(
    convertToTimeZoneDateFns(compatibleDate, {
      timeZone: SERVER_TIMEZONE,
    })
  )
}

/**
 *  Converts date from server timezone to specified timezone.
 */
export const convertServerDateToTz = (date: DateInput, tz: string) => {
  const clientDate = convertServerDateToClient(date)

  return convertToTimeZoneDateFns(clientDate, {
    timeZone: tz,
  })
}

export const formatDate = (
  date?: DateInput,
  format: keyof typeof DATE_FORMATS = 'long',
  tbdLabel = TBD_LABEL
) => {
  if (!date) {
    return tbdLabel
  }

  const compatibleDate = convertDate(date)

  return formatDateFns(compatibleDate, DATE_FORMATS[format])
}

export const customDateFormatter = (date: DateInput, format: string) => {
  const compatibleDate = convertDate(date)

  return formatDateFns(compatibleDate, format)
}

export const formatAuctionEndDate = (date: Date, tz: string) => {
  const seconds = differenceInSeconds(convertServerDateToClient(date), new Date())

  const formatted = formatDateFns(convertServerDateToTz(date, tz), DATE_FORMATS.long)

  if (seconds <= 0) {
    return `Ended on ${formatted} venue local time`
  }

  return `Ends on ${formatted} venue local time`
}
