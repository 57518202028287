import {
  convertToLocalTime,
  convertToTimeZone as convertToTimeZoneDateFns,
} from 'date-fns-timezone'
import { differenceInSeconds, format as DateFormat } from 'date-fns'

export const LOCALE = 'en-US'
export const SERVER_TIMEZONE = 'UTC'

export enum EVENT_DATE_FORMATS {
  long = 'EEE, MMMM do yyyy p',
  short = 'MM/dd/yyyy EEE',
  long2 = 'MMM dd EE · p',
  dateOnly = 'MMM dd',
  timeOnly = 'EE · p',
  short2 = 'MM/dd/yyyy',
}

/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const getServerTime = (): Date =>
  new Date(convertToTimeZoneDateFns(new Date(), { timeZone: SERVER_TIMEZONE }))

// TODO: method works incorrectly when converting dates from API. Should be rewriten on removed.
// Wed May 19 2021 17:00:00 GMT+0300 (Eastern European Summer Time) -> 2021/05/19 05:00:00
/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const dateToCompatibleFormat = (date: Date): string =>
  DateFormat(date, 'yyyy/MM/dd hh:mm:ss')

// fix for Safari browser https://burningthumb.com/safari-javascript-date-format/
/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const formatApiDate = (date: string) => {
  if (!date) {
    return dateToCompatibleFormat(new Date())
  }

  return date.replace(/-/g, '/').replace(/T/g, ' ')
}

/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const formatDateTime = (date: string, format: keyof typeof EVENT_DATE_FORMATS): string =>
  DateFormat(new Date(formatApiDate(date)), EVENT_DATE_FORMATS[format])

// convert time from server timezone to client timezone
/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const convertToClientTime = (date: string): Date =>
  new Date(
    convertToLocalTime(new Date(formatApiDate(date)), {
      timeZone: SERVER_TIMEZONE,
    })
  )

// convert time from from server timezone to client timezone and then to specified timezone
/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const convertToTimeZone = (date: string, timeZone: string) => {
  try {
    return new Date(
      convertToTimeZoneDateFns(convertToClientTime(date), {
        timeZone: timeZone || SERVER_TIMEZONE,
      })
    )
  } catch {
    return null
  }
}

/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
const formatCountDown = (seconds: number, format: string) => {
  const units = {
    secs: Math.floor(seconds % 60),
    mins60: Math.floor((seconds % 3600) / 60),
    hours: Math.floor(seconds / 3600),
    hours24: Math.floor((seconds % 86400) / 3600),
    days: Math.floor(seconds / 86400),
  }

  const formatNumber = (time: number): string => `${time < 10 ? '0' : ''}${time}`

  const formattingOutPut = {
    normal: ({ hours, mins60, secs }) =>
      `${formatNumber(hours)}:${formatNumber(mins60)}:${formatNumber(secs)}`,
    withLetters: ({ days, hours24, mins60 }) =>
      `${formatNumber(days)}d ${formatNumber(hours24)}h ${formatNumber(mins60)}m`,
  }

  return formattingOutPut[format](units)
}

/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const getCountDown = (
  startTime: string | Date,
  endTime: string | Date,
  format: 'normal' | 'withLetters'
) => {
  const type = {
    normal: 'Ended',
    withLetters: 'Ended',
  }

  const seconds = differenceInSeconds(new Date(endTime), new Date(startTime))

  if (seconds < 0 || Number.isNaN(seconds)) {
    return type[format]
  }

  // 7 days = 604800 sec
  if (seconds > 604800) {
    return `Ends on ${DateFormat(new Date(endTime), 'EEE MMMM d')}`
  }

  return formatCountDown(seconds, format)
}

/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */

export const formatEventDate = (
  date: string,
  venueTimeZone: string = null,
  format: keyof typeof EVENT_DATE_FORMATS
): string => {
  const convertedDate = convertToTimeZone(date, venueTimeZone)

  if (convertedDate) {
    return `${DateFormat(convertedDate, EVENT_DATE_FORMATS[format])}${venueTimeZone ? '' : ' UTC'}`
  }

  return '<TIME>'
}

/**
 * This is deprecated method. Please use time utils from utils.time.{methodName}
 */
export const formatAuctionEndDate = (
  endDate: string,
  venueTimeZone: string,
  format: 'short' | 'long' = 'short',
  prefix = ''
): string => {
  const currentTimeInVenueTimeZone = convertToTimeZoneDateFns(new Date(), {
    timeZone: venueTimeZone,
  })

  const convertedEndDate = convertToTimeZone(endDate, venueTimeZone)

  const seconds = differenceInSeconds(convertedEndDate, currentTimeInVenueTimeZone)

  if (Number.isNaN(seconds)) {
    return '<TIME>'
  }

  if (seconds < 0) {
    const endedText = prefix
      ? `${prefix} ended`
      : `Ended ${formatEventDate(endDate, venueTimeZone, 'short2')}`

    if (format === 'short') {
      return endedText
    }

    return `${endedText} on ${formatEventDate(endDate, venueTimeZone, 'long')} venue local time`
  }

  const endsText = prefix ? `${prefix} ends` : 'Ends'

  const hours = Math.floor(seconds / 3600)

  if (format === 'short') {
    return `${endsText} in ${formatCountDown(seconds, hours < 1 ? 'normal' : 'withLetters')}`
  }

  if (hours < 12) {
    return `${endsText} in ${formatCountDown(seconds, 'normal')}`
  }

  return `${endsText} on ${formatEventDate(endDate, venueTimeZone, 'long')} venue local time`
}
